import {
  generateTransactionId,
  isAccertifyEnabled,
  isAccertifyReady,
} from '@/features/monitoring';

import { formatDate } from '../../utils/format-date';

import type { BankCardsTypes } from '../../types/bank-cards-types';
import type { FieldValues } from 'react-hook-form';

export function useEditCardFormSubmit(
  updateBankCard: BankCardsTypes.Operations.UpdateBankCard.TMutation
) {
  return async function ({ cardExpiryDate, cardSecurityCode }: FieldValues) {
    const [expiryMonth, expiryYear] = formatDate(cardExpiryDate);

    if (!expiryMonth || !expiryYear) {
      return;
    }

    const transactionId = await generateTransactionId();

    const dvcRegisteredCallback = (transactionId?: string) => () => {
      updateBankCard.mutate({
        card: {
          cvv: cardSecurityCode,
          ...(!!transactionId && {
            device: {
              inauth_transaction_id: transactionId,
            },
          }),
          expiry: {
            month: expiryMonth,
            year: expiryYear,
          },
        },
      });
    };

    if (isAccertifyEnabled && !!transactionId && isAccertifyReady()) {
      window?._bcn?.dvc?.submit?.(dvcRegisteredCallback(transactionId));
    } else {
      dvcRegisteredCallback()();
    }
  };
}
