import { useEffect } from 'react';

const DEFAULT_ATTRIBUTES = {
  dvc: 'l',
  id: 'bcn',
  onload: dvcCollect,
  src: import.meta.env.VITE_REACT_APP_ACCERTIFY,
};

export const isAccertifyEnabled =
  import.meta.env.VITE_REACT_APP_ACCERTIFY_FLAG === 'true';

type TUseDvc = {
  dvc: string;
  id: string;
  onload: () => void;
  src: string;
};

export function useDvc(options?: TUseDvc) {
  options = { ...DEFAULT_ATTRIBUTES, ...options };

  useEffect(() => {
    if (!!options?.src && isAccertifyEnabled) {
      const script = document.createElement('script');

      script.setAttribute('async', 'true');
      script.setAttribute('type', 'text/javascript');
      script.setAttribute('src', options.src);
      script.setAttribute('id', options.id);
      script.setAttribute('dvc', options.dvc);
      script.setAttribute(
        'integrity',
        'sha384-OfTK3t9YNHClCMhUMAghYMm9KBXnH6X2tpsw8ym5phTNbaUJ3Q+TA11bJNdGm0EP'
      );
      script.setAttribute('crossorigin', 'anonymous');
      script.onload = options?.onload;

      document.body.appendChild(script);
    }

    return () => {
      const script = document.getElementById(options.id);

      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [options]);
}

export function dvcCollect(args?: Record<string, unknown>) {
  if (isAccertifyEnabled) {
    window?._bcn?.dvc?.collect?.(args);
  }
}

export async function generateTransactionId(): Promise<string | undefined> {
  if (!isAccertifyEnabled) {
    return undefined;
  }
  const tid = crypto.randomUUID();
  dvcCollect({ tid });
  // We need to ensure that the collect function (external API call) resolves before returning the transaction ID
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(tid);
    }, 700);
  });
}

export function isAccertifyReady(): boolean {
  return isAccertifyEnabled && !!window?._bcn?.dvc?.submit;
}
