import {
  generateTransactionId,
  isAccertifyEnabled,
  isAccertifyReady,
} from '@/features/monitoring';
import { BankCardsDialogErrorStates, useBankCardsStore } from '@/store';

import { createBankCardCipherText } from '../../utils/create-bank-card-cipher-text';
import { formatDate } from '../../utils/format-date';

import type { BankCardsTypes } from '../../types/bank-cards-types';
import type { FieldValues } from 'react-hook-form';

export function useAddCardFormSubmit(
  authorizeCard: BankCardsTypes.Operations.AuthorizeBankCard.TMutation,
  publicKey: BankCardsTypes.Operations.GetPublicKey.TResponse | undefined
) {
  const {
    setBankCardsDialogErrorState,
    setIsAddBankCardPending,
    setIsDefaultPay,
  } = useBankCardsStore().use.actions();

  const dvcRegisteredCallback =
    (cardAlias: string, cardCipherText: string, transactionId?: string) =>
    () => {
      if (!!cardCipherText) {
        authorizeCard.mutate({
          data: {
            alias: cardAlias,
            cardCipherText,
            ...(!!transactionId && {
              device: {
                inauth_transaction_id: transactionId,
              },
            }),
          },
        });
      }
    };

  return async ({
    cardAlias,
    cardExpiryDate,
    cardIsDefault,
    cardNumber,
    cardSecurityCode,
  }: FieldValues) => {
    setIsDefaultPay(cardIsDefault);
    const transactionId = await generateTransactionId();
    const [expiryMonth, expiryYear] = formatDate(cardExpiryDate);

    if (!expiryMonth || !expiryYear) {
      return;
    }

    if (!publicKey?.key_id || !publicKey?.public_key) {
      setBankCardsDialogErrorState(
        BankCardsDialogErrorStates.BANK_CARD_ADD_ERROR
      );
      return;
    }

    setIsAddBankCardPending(true);

    const cardCipherText = await createBankCardCipherText({
      data: {
        cvv: cardSecurityCode,
        expiry_month: expiryMonth,
        expiry_year: expiryYear,
        number: cardNumber?.replaceAll(' ', ''),
      },
      publicKey,
    });

    if (isAccertifyEnabled && !!transactionId && isAccertifyReady()) {
      window?._bcn?.dvc?.submit?.(
        dvcRegisteredCallback(cardAlias, cardCipherText, transactionId)
      );
    } else {
      dvcRegisteredCallback(cardAlias, cardCipherText)();
    }
  };
}
