import {
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import { ErrorFallback } from '@/components';
import { Feature } from '@/config';
import { useTheme } from '@/contexts/theme';
import {
  Account,
  Activity,
  Addresses,
  AuthFlybuys,
  Deleted,
  Expired,
  Home,
  LogOut,
  LoginRedirect,
  NotFound,
  TimedOut,
  Wallet,
} from '@/pages';
import { Routes as RouteConstants } from '@/routes/constants';

import { ProtectedRoute } from './protected-route';

export const AppRoutes = () => {
  const { theme } = useTheme();

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route errorElement={<ErrorFallback />}>
        <Route
          element={
            <ProtectedRoute>
              <Outlet />
            </ProtectedRoute>
          }
        >
          <Route element={<Home />} path={RouteConstants.HOME} />
          {theme.features.includes(Feature.ACTIVITY) && (
            <Route element={<Activity />} path={RouteConstants.ACTIVITY} />
          )}
          {theme.features.includes(Feature.WALLET) && (
            <Route element={<Wallet />} path={RouteConstants.WALLET} />
          )}
          {theme.features.includes(Feature.ACCOUNT) && (
            <Route element={<Account />} path={RouteConstants.ACCOUNT} />
          )}
          {theme.features.includes(Feature.ADDRESSES) && (
            <Route element={<Addresses />} path={RouteConstants.ADDRESSES} />
          )}
        </Route>
        <Route
          element={<LoginRedirect />}
          path={RouteConstants.LOGIN_REDIRECT}
        />
        {theme.features.includes(Feature.FLYBUYS) && (
          <Route element={<AuthFlybuys />} path={RouteConstants.FLYBUYS} />
        )}
        <Route element={<LogOut />} path={RouteConstants.LOGOUT} />,
        <Route element={<Deleted />} path={RouteConstants.DELETED} />,
        <Route element={<TimedOut />} path={RouteConstants.TIMED_OUT} />,
        <Route element={<Expired />} path={RouteConstants.EXPIRED} />,
        <Route element={<NotFound />} path="*" />
      </Route>
    )
  );

  return <RouterProvider router={router} />;
};
