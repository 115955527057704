import { LoggedOut } from '@/components';
import { profileContent } from '@/features/profile';
import { usePageTitle } from '@/hooks/use-page-title';

export const Expired = () => {
  usePageTitle('Expired');
  return (
    <LoggedOut
      description={'Please go back and start again.'}
      title={profileContent.TIMED_OUT_TITLE}
      warning
    />
  );
};
